export const state = {
    errorMessage: '',
};

export const mutations = {
    SET_ERROR_MESSAGE(state, message) {
        state.errorMessage = message;
    },
};

export const actions = {
    setErrorMessage({ commit }, message) {
        commit('SET_ERROR_MESSAGE', message);
    //setTimeout(() => commit('SET_ERROR_MESSAGE', ''), 5000); // Clear message after 5 seconds
    },
};
