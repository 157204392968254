import api from '../../services/apiService';

let fetchPromise = null; // Promise cache for the ongoing API request

export default {
    namespaced: true,
    state: {
        networkList: null, // Cache for account levels
    },
    mutations: {
        setNetworkList(state, networks) {
            state.networkList = networks;
        },
    },
    actions: {
        async fetchNetworksList({ commit, state }) {
            if (state.networkList) {
                // If data is already cached, return it
                return state.networkList;
            }

            if (!fetchPromise) {
                // If no ongoing request, make the API call
                fetchPromise = api.get('/admin/networks/select-neteworks')
                    .then((response) => {
                        if (response.status === 200 && Array.isArray(response.data?.data)) {
                            const networks = response.data.data.map((network) => ({
                                value: network.id,
                                label: network.name_short + ' - ' + network.name,
                            }));
                            commit('setNetworkList', networks); // Cache the data in Vuex
                            return networks;
                        }
                        return [];
                    })
                    .catch((error) => {
                        console.error('Error fetching networks:', error);
                        return [];
                    })
                    .finally(() => {
                        fetchPromise = null; // Reset the promise cache
                    });
            }

            // Return the ongoing fetch promise to all consumers
            return fetchPromise;
        },
    },
};

